import { useState } from "react";
import { useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle } from "styled-components";
import Footer from "./Footer";

import { auth } from "../Firebase/Firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth';

const SignUp = () =>{

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate();    

    const validateEntries = () =>{
        if(email){
            if(password){
                if(confirmPassword){
                    if(password === confirmPassword){
                        return true
                    } alert('Password and Confirm do not match'); return false
                } handleSomethingMissing('Confirm Password'); return false
            } handleSomethingMissing('Password'); return false
        } handleSomethingMissing('Email'); return false
    }

    const handleSignup = async (e) => {
    
        if(validateEntries()){
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                console.log('User signed up:', user);
                navigate('/')
            } catch (error) {
                if(error.code === 'auth/email-already-in-use'){
                    alert('Email Already in Use')
                }else if (error.code === "auth/invalid-email") {
                    alert("Invalid email address.");
                }else{
                    console.error('Error during signup:', error);
                }
            }
        }
    };

    const handleSomethingMissing = (type) =>{
        alert('Missing or invalid ' + type)
    }

    return(
        <SignUpDiv>
            <GlobalStyle />

            <KaitLogoDiv onClick={() => navigate('/')}>
                <KaitBlue>K</KaitBlue><KaitGreen>ai</KaitGreen><KaitBlue>t</KaitBlue>
            </KaitLogoDiv>
            
            <SignUpFormDiv>
                <SignUpHeaderText>SIGN-UP</SignUpHeaderText>
                <SignUpBodyDiv>

                    <UsernameDiv>
                        <UsernameTitle>Email Address:</UsernameTitle>
                        <UsernameInputDiv>
                            <UsernameInput type="text" onChange={(e) => setEmail(e.target.value)}/>
                        </UsernameInputDiv>
                    </UsernameDiv>

                    <PasswordDiv>
                        <PasswordTitle>Password:</PasswordTitle>
                        <PasswordInputDiv>
                            <PasswordInput type="password" onChange={(e) => setPassword(e.target.value)}/>
                        </PasswordInputDiv>
                    </PasswordDiv>

                    <ConfirmPasswordDiv>
                        <ConfirmPasswordTitle>Confirm Password:</ConfirmPasswordTitle>
                        <ConfirmPasswordInputDiv>
                            <ConfirmPasswordInput type="password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </ConfirmPasswordInputDiv>
                    </ConfirmPasswordDiv>

                    <ButtonsDiv>
                        <UpperButtonsDiv>
                            <SignUpButtonDiv>
                                <SignUpButton onClick={() => handleSignup()}>Sign Up</SignUpButton>
                            </SignUpButtonDiv>
                        </UpperButtonsDiv>
                        <BottonButtonsDiv>
                            <ForgotPasswordButtonDiv>
                                <ForgotPasswordButton>Forgot Password</ForgotPasswordButton>
                            </ForgotPasswordButtonDiv>

                            <AlreadyHaveButtonDiv>
                                <AlreadyHaveButton onClick={() => navigate('/login')}>Already Have an Account</AlreadyHaveButton>
                            </AlreadyHaveButtonDiv>
                        </BottonButtonsDiv>

                    </ButtonsDiv>
                </SignUpBodyDiv>
            </SignUpFormDiv>


            <Footer />

        </SignUpDiv>
    )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const SignUpDiv = styled.div`
    margin-top: 5%;
`

//KAIT logo
const KaitLogoDiv = styled.div`
    display: flex;
    margin-left: 30%;
`

const KaitBlue = styled.p`
    font-size: 120px;
    color: #6157E1;
`
const KaitGreen = styled.p`
    font-size: 120px;
    color: #4EB5A9;
`

//Header 
const SignUpFormDiv = styled.div`
    border: 1px solid #4EB5A9;
    border-radius: 10px;
    width: 50vw;
    height: 100vh;
    margin: auto;
    margin-top: 2%;
    background-color: #6157E1;
    overflow-y: auto;

    /* Firefox-specific scrollbar styles */
    scrollbar-color: #4EB5A9 transparent; /* Thumb color (border color), transparent track */
    scrollbar-width: thin; /* Thin scrollbar for Firefox */

    /* Webkit-based browser scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4EB5A9;
        border-radius: 10px; /* Rounded scrollbar thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #3b8f85; /* Darker color on hover */
    }
`;

const SignUpHeaderText = styled.p`
    color: white;
    font-size: 38px;
    text-align: center;
    margin-top: 5%;
`

const SignUpBodyDiv = styled.div`
    height: 80%;
    margin: 5%;
`

//Username
const UsernameDiv = styled.div`
    display: flex; 
    justify-content: space-between;
    padding: 10px;
`

const UsernameTitle = styled.p`
    font-size: 32px;    
    color: white;
`

const UsernameInputDiv = styled.div`
    float: right;
    width: 60%;
    height: 70px;
`

const UsernameInput = styled.input`
    margin-right: auto;
    height: 100%;
    width: 100%;
    background-color: #4EB5A9;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    font-size: 32px;  
    font-family: 'Nerko One', sans-serif;
    outline: none;
    caret-size: 3;
`

//Password
const PasswordDiv = styled.div`
    margin-top: 50px;
    display: flex; 
    justify-content: space-between;
    padding: 10px;
`

const PasswordTitle = styled.p`
    font-size: 32px;    
    color: white;
`

const PasswordInputDiv = styled.div`
    float: right;
    width: 60%;
    height: 70px;
`

const PasswordInput = styled.input`
    margin-right: auto;
    height: 100%;
    width: 100%;
    background-color: #4EB5A9;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    font-size: 32px;  
    font-family: 'Nerko One', sans-serif;
    outline: none;
    caret-size: 3;
`
//Confirm Password
const ConfirmPasswordDiv = styled.div`
    margin-top: 50px;
    display: flex; 
    justify-content: space-between;
    padding: 10px;
`

const ConfirmPasswordTitle = styled.p`
    font-size: 32px;    
    color: white;
`

const ConfirmPasswordInputDiv = styled.div`
    float: right;
    width: 60%;
    height: 70px;
`

const ConfirmPasswordInput = styled.input`
    margin-right: auto;
    height: 100%;
    width: 100%;
    background-color: #4EB5A9;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    font-size: 32px;  
    font-family: 'Nerko One', sans-serif;
    outline: none;
    caret-size: 3;
`

//Buttons
const ButtonsDiv = styled.div`
    width: 100%;
    background-color: red;
    display: inline;
`

//Sign Up Button
const UpperButtonsDiv = styled.div`
    
`

const SignUpButtonDiv = styled.div`
    margin-top: 10%;
    text-align: center;
`

const SignUpButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    width: 60%;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

//Botton Buttons
const BottonButtonsDiv = styled.div`
    margin-top: 10%;
    display: flex;
`

//Forgot Password Button
const ForgotPasswordButtonDiv = styled.div`
    margin-right: auto;
    width: 40%;
`

const ForgotPasswordButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    font-family: 'Nerko One', sans-serif;
`

//Already Have an Account Button
const AlreadyHaveButtonDiv = styled.div`
    margin-left: auto;
    width: 50%;
`

const AlreadyHaveButton = styled.button`
    font-size: 34px;
    color: white;
    width: 100%;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

export default SignUp