/*global chrome*/
//React
import { useEffect, useState } from "react";
//Styles/Pages
import { useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle } from "styled-components";
import Footer from "./Footer";
//Subscription
import { getSubscriptionLevel, subStatusCheck } from "./SubStatusCheck";
//Firebase
import { auth } from "../Firebase/Firebase";
import { onAuthStateChanged, signOut, sendPasswordResetEmail  } from "firebase/auth";

const Account = () =>{

    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [isSignedIn, setIsSignedIn] = useState(false)

    const [subbed, setSubbed] = useState(false)
    const [subscriberLevel, setSubbedLevel] = useState(null)

    useEffect(() =>{
        
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setEmail(user.email)

                const subbedOrNot = subStatusCheck(user.email);
                setSubbed(subbedOrNot)

                fetchSubLevel(user.email)

                setIsSignedIn(true)
            } else {
                setEmail('')
                setIsSignedIn(false)
                setSubbed(false)
            }
        });
    }, [])

    const logout = async () => {
        try {
          await signOut(auth).then(() =>{
            clearLocalStorage();
            navigate('/')
          });
        } catch (error) {
          alert("Error during sign out: ", error);
        }
    };

    const handlePasswordReset = async () => {
        try {
          await sendPasswordResetEmail(auth, email);
          alert("Password reset email sent! If an account exists with this email, you should receive password reset instructions within 30 minutes.");
        } catch (error) {
          if (error.code === "auth/user-not-found") {
            alert("No user found with this email.");
          } else if (error.code === "auth/invalid-email") {
            alert("Invalid email address.");
          } else {
            alert("An error occurred. Please try again later.");
          }
        }
    };
    
    //Fetches the Current Subscription from Firebase Functions, via the 'SubStatusCheck' component
    const fetchSubLevel = async (email) => {
        const data = await getSubscriptionLevel(email);
        setSubbedLevel(data);
    };

    const clearLocalStorage = () => {
        chrome.runtime.sendMessage('kcbehnbdpaobjdmgmfdhegmeebkpokpd', { user: email, action: 'deleteUserFromLocalStorage' })
    };

    return(
        <LoginDiv>
            <GlobalStyle />

            <KaitLogoDiv onClick={() => navigate('/')}>
                <KaitBlue>K</KaitBlue><KaitGreen>ai</KaitGreen><KaitBlue>t</KaitBlue>
            </KaitLogoDiv>
            
            <AccountBodyDiv>
                <AccountHeaderDiv>
                    <AccountHeaderText>ACCOUNT</AccountHeaderText>
                </AccountHeaderDiv>

                <UsernameDiv>
                    <UsernameLabel>Email Address:</UsernameLabel>
                    {isSignedIn ?
                        <UsernameData>{email}</UsernameData>
                    :   
                        <UsernameData>Not Signed In</UsernameData>
                    }
                </UsernameDiv>

                <SubStatusDiv>
                    <SubStatusLabel>Subscription Status:</SubStatusLabel>
                    {isSignedIn ?
                        <SubStatusInnerDiv>
                            {subbed ?
                                <SubStatusData>{subscriberLevel}</SubStatusData>
                            :
                                <SubStatusData>Not Subscribed</SubStatusData>
                            }
                        </SubStatusInnerDiv>
                        :
                        <SubStatusInnerDiv>
                            <SubStatusData>Not Signed In</SubStatusData>
                        </SubStatusInnerDiv>
                    }

                </SubStatusDiv>

                <ButtonsDiv>
                    <UpperButtonsDiv>
                        <SignOutButtonDiv>
                            {isSignedIn ?
                                <SignOutButton onClick={() => logout()}>Sign Out of Account</SignOutButton>
                            :
                                <SignOutButton onClick={() => navigate('/login')}>Go to Login page</SignOutButton>
                            }
                        </SignOutButtonDiv>
                    </UpperButtonsDiv>

                    <BottonButtonsDiv>
                        <ManageSubButtonDiv>
                            <ManageSubButton onClick={() => window.open("https://billing.stripe.com/p/login/00gg0le2J54q7WEdQQ")}>Manage Subscription</ManageSubButton>
                        </ManageSubButtonDiv>

                        <ForgotPasswordButtonDiv>
                            <ForgotPasswordButton onClick={() => handlePasswordReset()}>Forgot Password</ForgotPasswordButton>
                        </ForgotPasswordButtonDiv>
                    </BottonButtonsDiv>

                </ButtonsDiv>
            </AccountBodyDiv>

            <Footer />

        </LoginDiv>
    )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const LoginDiv = styled.div`
    margin-top: 5%;
`

//KAIT logo
const KaitLogoDiv = styled.div`
    display: flex;
    margin-left: 30%;
`

const KaitBlue = styled.p`
    font-size: 120px;
    color: #6157E1;
`
const KaitGreen = styled.p`
    font-size: 120px;
    color: #4EB5A9;
`

//Account Body
const AccountBodyDiv = styled.div`
    width: 50vw;
    height: 100vh;
    background-color: #6157E1;
    margin: auto;
    border-radius: 20px;
`

//Header
const AccountHeaderDiv = styled.div`

`


const AccountHeaderText = styled.p`
    text-align: center;
    color: white;
    font-size: 48px;
    padding: 10px;
    padding-top: 5%;
`
//Username
const UsernameDiv = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 10%;
`

const UsernameLabel = styled.p`
    color: white;
    font-size: 36px;
    text-align: center;
    margin-left: 10%;
`
const UsernameData = styled.p`
    color: white;
    font-size: 36px;
    text-align: center;
    margin-left: auto;
    margin-right: 15%;
`
//Subscription Status
const SubStatusDiv = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
`

const SubStatusLabel = styled.p`
    color: white;
    font-size: 36px;
    text-align: center;
    margin-left: 10%;
    margin-top: 5%;
`

const SubStatusInnerDiv = styled.div`
    color: white;
    font-size: 36px;
    text-align: center;
    margin-left: auto;
    margin-right: 15%;
    margin-top: 5%;
`

const SubStatusData = styled.p`

`

//Buttons
const ButtonsDiv = styled.div`
    display: inline;
`

//Sign Up Button
const UpperButtonsDiv = styled.div`
`

const SignOutButtonDiv = styled.div`
    text-align: center;
    margin-top: 10%;
`

const SignOutButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    width: 40%;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

//Botton Buttons
const BottonButtonsDiv = styled.div`
    display: flex;
    padding: 10%;
`

//Forgot Password Button
const ManageSubButtonDiv = styled.div`
    margin-right: auto;
    width: 50%;
`

const ManageSubButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    font-family: 'Nerko One', sans-serif;
`

//Already Have an Account Button
const ForgotPasswordButtonDiv = styled.div`
    margin-left: auto;
    width: 40%;
`

const ForgotPasswordButton = styled.button`
    font-size: 34px;
    color: white;
    width: 100%;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

export default Account