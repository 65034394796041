import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {

    const navigate = useNavigate();

    return(
    <>
    <GlobalStyle />
    
    <KaitLogoDiv onClick={() => navigate('/')}>
        <KaitBlue>K</KaitBlue><KaitGreen>ai</KaitGreen><KaitBlue>t</KaitBlue>
    </KaitLogoDiv>

    <PrivacyPolicyDiv>
        <PrivacyPolicyHeader>Privacy Policy for Kait-AI</PrivacyPolicyHeader>

        <SectionTitle>Effective October 2024</SectionTitle>

        <SectionDataContainer>
            <SectionTitle>1. Introduction</SectionTitle>
            <SectionData>This Privacy Policy explains how Kait-AI uses, and protects your information when you use our Chrome extension. By using the Extension, you consent to the practices described in this policy.</SectionData>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>2. Information We Collect</SectionTitle>
            <SectionData>We may collect the following types of information:</SectionData>
            <SectionUL>
                <SectionLI>Personal Information: If you choose to use features that require authentication, we may collect your email address and other identifying information.</SectionLI>
                <SectionLI>Payment Info: We collect the level of subscription currently obtained, but hold no payment information, all of which is managed externally.</SectionLI>
                <SectionLI>Usage Data: We may collect information about how you use the Extension, including interaction patterns, preferences, and other analytics data.</SectionLI>
            </SectionUL>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>3. How We Use Your Information</SectionTitle>
            <SectionData>We use the information we collect for the following purposes:</SectionData>
            <SectionUL>
                <SectionLI>To track subscription level and monthly uses.</SectionLI>
                <SectionLI>To communicate with you, including sending updates, notifications and password resets.</SectionLI>
                <SectionLI>To analyze usage patterns to enhance functionality and user experience.</SectionLI>
            </SectionUL>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>4. Data Sharing and Disclosure</SectionTitle>
            <SectionData>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</SectionData>
            <SectionUL>
                <SectionLI>Service Providers: We may share your information with trusted third-party service providers who assist us in operating our Extension, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.</SectionLI>
                <SectionLI>Legal Compliance: We may disclose your information if required by law or in response to valid requests by public authorities.</SectionLI>
            </SectionUL>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>5. Data Security</SectionTitle>
            <SectionData>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</SectionData>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>6. Your Rights</SectionTitle>
            <SectionData>Depending on your location, you may have the following rights regarding your personal information:</SectionData>
            <SectionUL>
                <SectionLI>The right to access and receive a copy of your personal data.</SectionLI>
                <SectionLI>The right to rectify any inaccurate or incomplete information.</SectionLI>
                <SectionLI>The right to request the deletion of your personal data.</SectionLI>
            </SectionUL>
            <SectionData>To exercise these rights, please contact us at kaitextension@gmail.com.</SectionData>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>7. Changes to This Privacy Policy</SectionTitle>
            <SectionData>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this Privacy Policy periodically for any changes.</SectionData>
        </SectionDataContainer>

        <SectionDataContainer>
            <SectionTitle>8. Contact Us</SectionTitle>
            <SectionData>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at: kaitextension@gmail.com</SectionData>
        </SectionDataContainer>
    </PrivacyPolicyDiv>


        <FooterDiv>
            <CopyrightText>&copy; Copyright 2024 kait-ai.com. All rights reserved.</CopyrightText>
        </FooterDiv>

    </>
    );
};

// Global styles for the font
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Nerko+One&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Nerko One', cursive;
    background-color: #2F284F;
  }
`;

//KAIT logo
const KaitLogoDiv = styled.div`
  display: flex;
  margin-left: 20%;
`

const KaitBlue = styled.p`
  font-size: 120px;
  color: #6157E1;
`
const KaitGreen = styled.p`
  font-size: 120px;
  color: #4EB5A9;
`

//Policy Header
const PrivacyPolicyHeader = styled.h1`
  font-size: 2.5rem;
`;

const PrivacyPolicyDiv = styled.div`
    color: white;
    min-height: 100vh; /* Ensures the container takes the full height of the viewport */
    padding: 5%;
`;


//Section Specific
const SectionDataContainer = styled.div`
    margin-top: 2%;
`

const SectionTitle = styled.p`
    color: white;
    font-size: 24px;
`

const SectionData = styled.p`

`

//UL/LI
const SectionUL = styled.ul`
    list-style-type: none; 
    padding-left: 20px; 
    text-align: left;
`
const SectionLI = styled.li`
    margin: 5px 0;
`

//Footer
const FooterDiv = styled.div`
    text-align: center;
`

const CopyrightText = styled.p`
    color: white;
    font-size: 32px;
`

export default PrivacyPolicy;
