import styled, {createGlobalStyle} from 'styled-components'

import KaitAIwebsiteGIF from '../assets/KaitGif.gif'
import { useEffect } from 'react';
import { auth } from '../Firebase/Firebase';
import { sendUserToChrome } from './SubStatusCheck';


const Main = () =>{

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            sendUserToChrome(user); 
        });

        return () => {
            unsubscribe();
        };
    }, []); 

    return(
    <MainDiv>
        <GlobalStyle />
        <ContentText>Kait is a Google Chrome extension that utlizes AI to complete tasks on webpages you visit. Simply highlight some text, open the extension, and choose what to do with the text. It's that simple!</ContentText>
        <ExampleDiv>
            <ExampleText>For example: Don't feel like reading a long article to get the gist of it? Simply highlight the whole article, then ask Kait to  summarize it for you!</ExampleText>
            <HowToGif src={KaitAIwebsiteGIF} alt="How To GIF"/>
        </ExampleDiv>
    </MainDiv>
    )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
  }
`;

const MainDiv = styled.div`
    padding: 0;
    margin: 0;
`

const ContentText = styled.p`
    color: white;
    font-size: 42px;
    padding: 10px;
    text-align: center;
`

const ExampleDiv = styled.div`
    margin-top: 10%;
    text-align: center;
`

const ExampleText = styled.p`
    color: white;
    font-size: 32px;
`

const HowToGif = styled.img`
    margin-top: 5%;
    width: 100%;  
    height: 60%;
`;

export default Main