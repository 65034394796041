import styled from "styled-components"

import { useNavigate } from "react-router-dom"

const Footer = () =>{
    
    const navigate = useNavigate();   

    return(
        <FooterDiv>
            <ReportProblemDiv>
                <CopyrightText>&copy; Copyright 2024 kait-ai.com. All rights reserved.</CopyrightText>
                <ReportProblemText href="mailto:kaitextension@gmail.com">Report a Problem</ReportProblemText>
                <button onClick={() => navigate('/privacypolicy')}>Privacy Policy</button>
            </ReportProblemDiv>
        </FooterDiv>
    )
}

//Report a Problem
const FooterDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    margin-top: 10%;
`

const CopyrightText = styled.p`
    color: white;
    font-size: 32px;
`

const ReportProblemDiv = styled.div`
    text-align: center;
    margin-top: auto;
`

const ReportProblemText = styled.p`
    color: white;
    margin-bottom: auto;
    font-size: 20px;
    text-decoration: underline;
`

export default Footer