/*global chrome*/
import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useNavigate } from 'react-router-dom'

import { getSubscriptionLevel } from "./SubStatusCheck";

import Footer from "./Footer";

import {auth, provider} from "../Firebase/Firebase"
import { signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import {Google} from '@styled-icons/boxicons-logos'

const Login = () =>{
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [subscriberLevel, setSubbedLevel] = useState(null)

    const navigate = useNavigate();    

    const validateEntries = () =>{
        if(email){
            if(password){
                return true;
            } handleSomethingMissing('Password'); return false
        } handleSomethingMissing('Email'); return false
    }

    const handlePasswordReset = async () => {
        try{
          await sendPasswordResetEmail(auth, email);
          alert(`A password reset email has been sent to ${email}! If an account exists with this email, you will receive further instructions in that inbox.`);
        }catch (error) {
            alert("Please enter a valid email in the 'Email Address' field, then select the password reset!");
                console.error("Error sending password reset email:", error);
            if(error.code === "auth/user-not-found") {
                alert("Please enter a valid email in the 'Email Address' field, then select the password reset!");
            }else if (error.code === "auth/invalid-email") {
                alert("Please enter a valid email in the 'Email Address' field, then select the password reset!");
            }
        }
    };

    //Fetches the Current Subscription from Firebase Functions, via the 'SubStatusCheck' component

    const handleLogin = async () => {
        console.log('Attempting Login')
        if(validateEntries()){
            try {
                // Sign in the user with email and password
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                if(user){
                    navigate('/')
                }else{
                    alert('Something went wrong with signin')
                }

            }catch (error) {
                alert('Error durring login, please try again or contact support.');
            }
        }
    };

    const signInWithGoogle = async () => {
        try {
            const user = await signInWithPopup(auth, provider)
                
            if (user){
                navigate('/')
            }else{
                alert('Something went wrong with signin')
            }

        } catch (error) {
            alert("Error during Google Sign-In:", error);
        }
    };

    const handleSomethingMissing = (type) =>{
        alert('Missing or invalid ' + type)
    }

    return(
        <LoginDiv>
            <GlobalStyle />

            <KaitLogoDiv onClick={() => navigate('/')}>
                <KaitBlue>K</KaitBlue><KaitGreen>ai</KaitGreen><KaitBlue>t</KaitBlue>
            </KaitLogoDiv>
            
            <LoginFormDiv>
                <LoginHeaderText>LOGIN</LoginHeaderText>
                <LoginBodyDiv>

                <form>
                    <UsernameDiv>
                        <UsernameTitle>Email Address:</UsernameTitle>
                        <UsernameInputDiv>
                            <UsernameInput type="text" autoComplete="mail" onChange={(e) => setEmail(e.target.value)}/>
                        </UsernameInputDiv>
                    </UsernameDiv>

                    <PasswordDiv>
                        <PasswordTitle>Password:</PasswordTitle>
                        <PasswordInputDiv>
                            <PasswordInput type="password" autoComplete="current-password" onChange={(e) => setPassword(e.target.value)}/>
                        </PasswordInputDiv>
                    </PasswordDiv>
                </form>
                    <ButtonsDiv>
                        <UpperButtonsDiv>
                            <LoginButtonDiv>
                                <LoginButton onClick={() => handleLogin()}>Sign In</LoginButton>
                            </LoginButtonDiv>

                            <GoogleLoginButtonDiv>
                                <GoogleLoginButton onClick={() => signInWithGoogle()}>
                                    <GoogleButtonInnerDiv>
                                        <GoogleIconDiv>
                                            <GoogleIcon />
                                        </GoogleIconDiv>
                                        <GoogleButtonText>Sign in with Google</GoogleButtonText>
                                    </GoogleButtonInnerDiv>
                                </GoogleLoginButton>
                            </GoogleLoginButtonDiv>

                        </UpperButtonsDiv>
                        <BottonButtonsDiv>
                            <ForgotPasswordButtonDiv>
                                <ForgotPasswordButton onClick={() => handlePasswordReset()}>Forgot Password</ForgotPasswordButton>
                            </ForgotPasswordButtonDiv>

                            <CreateAccountButtonDiv>
                                <CreateAccountButton onClick={() => navigate('/signup')}>Create an Account</CreateAccountButton>
                            </CreateAccountButtonDiv>
                        </BottonButtonsDiv>
                    </ButtonsDiv>
                </LoginBodyDiv>
                
            </LoginFormDiv>

            <Footer />

        </LoginDiv>
    )
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const LoginDiv = styled.div`
    margin-top: 5%;
`

//KAIT logo
const KaitLogoDiv = styled.div`
    display: flex;
    margin-left: 30%;
`

const KaitBlue = styled.p`
    font-size: 120px;
    color: #6157E1;
`
const KaitGreen = styled.p`
    font-size: 120px;
    color: #4EB5A9;
`

//Header 
const LoginFormDiv = styled.div`
    border: 1px solid #4EB5A9;
    border-radius: 10px;
    width: 50vw;
    height: 90vh;
    margin: auto;
    margin-top: 2%;
    background-color: #6157E1;
    overflow-y: auto;

    /* Firefox-specific scrollbar styles */
    scrollbar-color: #4EB5A9 transparent; /* Thumb color (border color), transparent track */
    scrollbar-width: thin; /* Thin scrollbar for Firefox */

    /* Webkit-based browser scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4EB5A9;
        border-radius: 10px; /* Rounded scrollbar thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #3b8f85; /* Darker color on hover */
    }
`;


const LoginHeaderText = styled.p`
    color: white;
    font-size: 48px;
    text-align: center;
    margin-top: 5%;
`

const LoginBodyDiv = styled.div`
    height: 80%;
    margin: 5%;
`

//Username
const UsernameDiv = styled.div`
    display: flex; 
    justify-content: space-between;
    padding: 10px;
    margin-top: 10%;
`

const UsernameTitle = styled.p`
    font-size: 32px;     
    color: white;
`

const UsernameInputDiv = styled.div`
    float: right;
    width: 60%;
    height: 70px;
`

const UsernameInput = styled.input`
    margin-right: auto;
    height: 100%;
    width: 100%;
    background-color: #4EB5A9;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    font-size: 32px;  
    font-family: 'Nerko One', sans-serif;
    outline: none;
    caret-size: 3;
`

//Password
const PasswordDiv = styled.div`
    margin-top: 50px;
    display: flex; 
    justify-content: space-between;
    padding: 10px;
`

const PasswordTitle = styled.p`
    font-size: 32px;    
    color: white;
`

const PasswordInputDiv = styled.div`
    float: right;
    width: 60%;
    height: 70px;
`

const PasswordInput = styled.input`
    margin-right: auto;
    height: 100%;
    width: 100%;
    background-color: #4EB5A9;
    border-radius: 10px;
    color: white;
    padding-left: 10px;
    font-size: 32px;  
    font-family: 'Nerko One', sans-serif;
    outline: none;
    caret-size: 3;
`

//Buttons
const ButtonsDiv = styled.div`
    display: inline;
`


//Upper Body Buttons
const UpperButtonsDiv = styled.div`

`

//Login Button
const LoginButtonDiv = styled.div`
    text-align: center;
    margin-top: 10%;
`

const LoginButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    width: 60%;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

//Google Login Button

const GoogleLoginButtonDiv = styled.div`
    text-align: center;
    margin-top: 10px;
`

const GoogleLoginButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    width: 60%;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

const GoogleButtonText = styled.p`
    margin-right: auto;
`

    //Button inner div  
const GoogleButtonInnerDiv = styled.div`
    display: flex;
    flex-direction: row;
`

const GoogleIconDiv = styled.div`
    margin-right: auto;
    margin-left: 10%;
    padding: 5px;
`

const GoogleIcon = styled(Google)`
    width: 32px;
    height: 32px;
    color: white;
`

//Botton Buttons
const BottonButtonsDiv = styled.div`
    margin-top: 10%;
    display: flex;
`

//Forgot Password Button
const ForgotPasswordButtonDiv = styled.div`
    margin-right: auto;
    width: 40%;
`

const ForgotPasswordButton = styled.button`
    font-size: 34px;
    color: white;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    width: 100%;
    font-family: 'Nerko One', sans-serif;
`

//Already Have an Account Button
const CreateAccountButtonDiv = styled.div`
    margin-left: auto;
    width: 50%;
`

const CreateAccountButton = styled.button`
    font-size: 34px;
    color: white;
    width: 100%;
    background-color: #4EB5A9;
    padding: 10px;
    border-radius: 20px;
    font-family: 'Nerko One', sans-serif;
`

export default Login