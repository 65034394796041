import React from 'react';
import styled from 'styled-components';

const CancelSubscriptionModal = ({ isOpen, onClose, onCancel, onConfirm }) => {
    if (!isOpen) return null;
  
    return (
      <Overlay>
        <ModalContainer>
            <CloseButton onClick={onClose}>X</CloseButton>
          <Header>
            <h2>Upgrade Subscription</h2>
          </Header>
          <Body>
            <p>In order to upgrade to a new subscription, please cancel your current subcscription</p>
          </Body>
          <Footer>
            <Button className="confirm" onClick={onConfirm}>Yes, Cancel</Button>
            <Button className="cancel" onClick={onCancel}>No, Keep Subscription</Button>
          </Footer>
        </ModalContainer>
      </Overlay>
    );
};
  
const Overlay = styled.div`
    position: fixed;
    color: white;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    background-color: #6157E1;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    position: relative;
    text-align: center;
`;

const Header = styled.header`
    margin-bottom: 15px;

    h2 {
        margin: 0;
        font-size: 1.5rem;
    }
`;

const Body = styled.div`
    margin-bottom: 20px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled.button`
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    color: white;
    font-family: 'Nerko One', sans-serif;
    font-size: 18px;
    
    &.confirm {
        background-color: red;
    }

    &.cancel {
        background-color: #4EB5A9;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    right: 5%;
    border: none;
    background: none;
    font-size: 1.2rem;
    cursor: pointer;
`;
  
export default CancelSubscriptionModal;