/*global chrome*/
import { db } from '../Firebase/Firebase';
import { doc, getDoc } from 'firebase/firestore';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const stripe = require('stripe')('sk_test_51PuNVjKHrBbpCbDdMYRpOJ9Zwz5mQLjttrdhGVIk0y1Wx3DcHxF1rOXHbWFA8exCtSFHOad8rlA0niZUQmYwZKk400CRStTITR');

export const subStatusCheck = async (email) => {
    try {
        // Retrieve customer(s) by email
        const customers = await stripe.customers.list({
            email: email,
            limit: 1, // Limit to 1 to speed up the lookup if there's only one customer expected per email
        });
    
        if (customers.data.length === 0) {
            console.log('No customer found with this email.');
            return false;
        }
    
        const customerId = customers.data[0].id;

        // Fetch subscriptions for the customer
        const subscriptions = await stripe.subscriptions.list({
            customer: customerId,
            status: 'all', // Fetch all subscriptions regardless of their status
        });
    
        // Check if any subscription is active
        const activeSubscriptions = subscriptions.data.filter(subscription => subscription.status === 'active');
    
        if (activeSubscriptions.length > 0) {
            return true
        } else {
            return false
        }
    } catch (error) {
        console.log('Error: ' + error)
        return false
    }
}

export const getSubscriptionLevel = async (email) => {

    if(!email){
        console.log('Cannot proceed without email')
        return
    }

    try {
        const response = await fetch('https://us-central1-kait-ai.cloudfunctions.net/getSubscriptionByEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();

        if (data.productLevel) {
            const subName = data.productLevel
            console.log('Subscription Found: ' + subName)
            return subName
        } else {
            console.log('No Subscription Found for ' + email)
            return null
        }
    } catch (error) {
        console.log('Error While Finding Sunscription: ' + error)
        return null
    }  
};

export const getSubscriptionIdByEmail = async (email) => {
    try {
        // Reference to the document with the email as the ID
        const docRef = doc(db, 'subscriptions', email);
        
        // Fetch the document
        const docSnap = await getDoc(docRef);
        
        if (docSnap) {
            // Get the subscriptionId from the document
            const subscriptionId = docSnap.data().subscriptionId;
            return subscriptionId;
        } else {
            console.error('No subscription document found for this email');
            return
        }
    } catch (error) {
        console.error('Error fetching subscription ID:', error);
        return
    }
}









// ==============================   CUSTOM TOKEN CREATION ================================


export const sendUserToChrome = async (user) => {

    if (!user || !user.email) {
        console.log('Nobody is signed in @ Main!');
        return;
    }

    const data = await getSubscriptionLevel(user.email);

    try {
        // Fetch custom token from your server
        const response = await fetch('https://us-central1-kait-ai.cloudfunctions.net/createCustomToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
        });

        if (!response.ok) {
            console.log('Failed to create custom token');
            return
        }

        const { customToken } = await response.json();

        try{
            console.log('Attempting to Send')
            // Send user data to the Chrome extension
            chrome.runtime.sendMessage('alcmhgkkobonhdfmiiepidpphiajhppa', { user: user.email, subbLevel: data, tokenId: customToken, action: 'getChromeLocalData' });
        }catch(error){
            console.error('Failed to send data to Chrome: ', error.message)
        }
        
    } catch (error) {
        console.log('Error sending user data to the extension: ', error.message);
    }
};
